import React from "react"
import styled from "styled-components"

const BusinessHumanLists = styled.div`
    padding-top: 15px;
`
const BusinessHumanList = styled.li`
    font-size: 14px;
    display: list-item;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 17px;
`

const HumanExplanation = styled.p`
    font-weight: 400;
    line-height: 17px;
`

const MobileVisionLists = (props) => {

    return (
        <BusinessHumanLists>
            <BusinessHumanList>{props.list}</BusinessHumanList>
            <HumanExplanation>{props.sentence}</HumanExplanation>
        </BusinessHumanLists>
    )
}

export default MobileVisionLists