import { useStaticQuery, graphql } from "gatsby"

const VisionImages = (request) => {
    const data = useStaticQuery(graphql`
        query {
            ceo: file(relativePath: { eq: "PC/vision-picture-ceo.png" }) {
                childImageSharp {
                    fixed(width: 362, height: 463) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            cto: file(relativePath: { eq: "PC/vision-picture-cto2.png" }) {
                childImageSharp {
                    fixed(width: 362, height: 463) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            ceoImg: file(relativePath: { eq: "PC/vision-ceo.png" }) {
                childImageSharp {
                    fixed(width: 900, height: 455) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
        }
    `)

    return data[request].childImageSharp.fixed
}

export default VisionImages