import React from "react"
import styled from "styled-components"
import { VisionBusinessIndustry, VisionBusinessHuman } from "../molecules/VisionBusinesses"
import Spacer from "../atoms/Spacer"

const IntroduceBusinessOuter = styled.div`
    display: inline-block;
    width: 100%;
    height: auto;
`
const IntroduceBusinessInner = styled.div`
    width: 58%;
    min-width: 900px;
    height: auto;
    margin: 0 auto;
`

const BusinessTitle = styled.h2`
    font-size: 28px;
    font-weight: 400;
`

const VisionIntroduceBusiness = () => {

    return (
        <IntroduceBusinessOuter>
            <IntroduceBusinessInner>
                <Spacer height="50px" />
                <BusinessTitle>事業内容</BusinessTitle>
                <Spacer height="50px" />
                <VisionBusinessIndustry />
                <Spacer height="40px" />
                <VisionBusinessHuman />
                <Spacer height="120px" />
            </IntroduceBusinessInner>
        </ IntroduceBusinessOuter>
    )
}

export default VisionIntroduceBusiness