import React from "react"
import styled from "styled-components"
import SEO from "../seo"
import Hero from "../PC/organisms/Hero"
import Pages from "../../data/PagesData"
import Header from "../PC/organisms/Header"
import VisionTop from "../PC/organisms/VisionTop"
import Footer from "../PC/organisms/Footer"
import VisionIntroduceBusiness from "../PC/organisms/VisionIntroduceBusiness"
import MobileVisionTop from "../mobile/organisms/MobileVisionTop"
import MobileVisionIntroduceBusiness from "../mobile/organisms/MobileVisionIntroduceBisiness"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    height: auto;
    width: 100%;
`
const VisionSEOData = {
    title: 'ビジョン',
    lang: 'jp',
    url: '/vision',
    description:'地域産業をアップデートする UPDATE THE INDUSTRY',
}


const VisionTemplate = () => {

    const page = Pages[2]
    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO
                title={VisionSEOData.title}
                lang={VisionSEOData.lang}
                url={VisionSEOData.url}
                description={VisionSEOData.description}
            />
            {breakPoints.pc
                ?
                    <React.Fragment>
                        <Hero page={page.name} />
                        <VisionTop />
                        <VisionIntroduceBusiness />
                        <Footer />
                        <Header page={page.name} />
                    </React.Fragment>
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <React.Fragment>
                        <MobileVisionTop />
                        <MobileVisionIntroduceBusiness />
                    </React.Fragment>
                :
                    null
            }
        </Template>
    )
}

export default VisionTemplate