import React from "react"
import styled from "styled-components"
import VisionCatchcopy from "../molecules/VisionCatchcopy"
import VisionExecutives from "../molecules/VisionExecutives"
import Spacer from "../atoms/Spacer"
import { HorizontalLine } from "../atoms/Decoration"
import { DoubleLabelButton } from "../atoms/Button"

const VisionTopOuter = styled.div`
    display: inline-block;
    width: 100%;
    height: auto;
`

const VisionTop = () => {

    return (
        <VisionTopOuter>
            <VisionCatchcopy/>
            <VisionExecutives/>
            <Spacer height="150px" />
            <DoubleLabelButton
                jpLabel='メンバーについて'
                enLabel='MEMBER'
                href='/company'
            />
            <Spacer height="60px" />
            <HorizontalLine />
        </VisionTopOuter>
    )
}

export default VisionTop