import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Executive = styled.div`
    display: inline-block;
    margin: 70px 50px;
    text-align: center;
    width: 362px;
`
const ExecutivePosition = styled.p`
    display: inline-block;
    margin: 20px 0px 10px 0px;
    width: 100%;
    letter-spacing: 0.3px;
`
const ExecutiveName = styled.p`
    display: inline-block;
    font-size: 36px;
    letter-spacing: 4px;
`


const VisionExecutive = (props) => {

    return (
        <Executive>
            <Img fixed={props.image} />
            <ExecutivePosition>{props.position}</ ExecutivePosition>
            <ExecutiveName>{props.name}</ ExecutiveName>
        </Executive>
    )
}

export default VisionExecutive