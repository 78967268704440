import React from "react"
import styled from "styled-components"
import Spacer from "../../PC/atoms/Spacer"
import { MobileVisionBusinessIndustry, MobileVisionBusinessHuman } from "../molecules/MobileVisionBusinesses"

const IntroduceBusinessOuter = styled.div`
    display: inline-block;
    width: 100%;
    height: auto;
`
const IntroduceBusinessInner = styled.div`
    width: 80%;
    min-width: 250px;
    height: auto;
    margin: 0 auto;
`

const BusinessTitle = styled.h2`
    font-size: 18px;
    font-weight: 400;
`

const MobileVisionIntroduceBusiness = () => {

    return (
        <IntroduceBusinessOuter>
            <IntroduceBusinessInner>
                <Spacer height="50px" />
                <BusinessTitle>事業内容</BusinessTitle>
                <Spacer height="30px" />
                <MobileVisionBusinessIndustry />
                <Spacer height="30px" />
                <MobileVisionBusinessHuman />
                <Spacer height="70px" />
            </IntroduceBusinessInner>
        </ IntroduceBusinessOuter>
    )
}

export default MobileVisionIntroduceBusiness