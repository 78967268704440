import React from "react"
import styled from "styled-components"
import VisionTopicBar from "../atoms/VisionTopicBar"
import VisionLists from "../atoms/VisionLists"

const BusinessIndustryOuter = styled.div`
    display: inline-block;
    width: 100%;
`
const IndustryCatchCopy = styled.p`
    font-weight: 400;
`

const IndustryExampleLists = styled.ul`
    list-style: inside;
    padding-top: 15px;
    margin-left: 50px;
`

const IndustryExampleList = styled.li`
    font-size: 16px;
    display: list-item;
    margin: 15px 0px 15px 0px;
`

export const VisionBusinessIndustry = () => {

    const IndustryExamples = [
        "釣り具のポイントのコミュニティ創造事業",
        "岡野バルブの次世代バルブメンテナンス事業",
        "福岡ひびき信用金庫のATM不正検知システム",
        "クラウン製パンの技術者育成システム",
        "受付AI窓口システム",
        "生体バイオセンサの鮮度トラッキングシステム",
        "次世代ECサイト",
        "AR/VRコミュニケーション"
    ]

    return (
        <BusinessIndustryOuter>
            <VisionTopicBar title="未来産業創造事業　UPDATE THE INDUSTRY" />
            <IndustryCatchCopy>地域の産業基盤・地域資源を活かした新しい事業を創造し、世界市場でマーケットリーダーとなるような事業体を生み出す。</IndustryCatchCopy>
            <IndustryExampleLists>
                {IndustryExamples.map((p,index) => (
                    <IndustryExampleList key={`IndExamples${index}`}>{p}</IndustryExampleList>
                ))}
            </IndustryExampleLists>
        </BusinessIndustryOuter>
    )

}

const BusinessHumanOuter = styled.div`
    display: inline-block;
    width: 100%;
    font-weight: 400;
`
const HumanCatchCopy = styled.p`
    font-weight: 400;
`

const AboutSeminars = styled.li`
    font-size: 14px;
    margin: 18px 0px 18px 0px;
    list-style-type: none;
    font-weight: 400;
`

const BusinessHumanExamples = styled.div`
    margin-left: 50px;
`

export const VisionBusinessHuman = () => {

    const explanations = [
        {
            list: "出向/インターンシップ",
            sentence: "クアンドへ出向し、実践の中で経験を積むことで、総合力を鍛える。"
        },
        {
            list: "アドバイザリー/コンサルティング",
            sentence: "専門家派遣により、企業の持つ課題の抽出、戦略の策定、組織改革など幅広く支援。"
        },
        {
            list: "セミナー/ワークショップ",
            sentence: "これからの時代を生き抜くために必要な、基礎教養としての知識を提供。"
        }
    ];

    const seminars = [
        "- AI/IoT（基礎編、技術編、実践編）",
        "- マーケティング・ブランディング",
        "- リーダーシップ",
        "- デザインシンキング　　など"
    ];

return (
    <BusinessHumanOuter>
        <VisionTopicBar title="未来人材育成事業　UNLEASH THE POWER OF YOU" />
        <HumanCatchCopy>テクノロジーとマーケットを深く理解し、他者を巻き込みながら、新しい事業が生み出せる次世代のリーダーやチームを創出。</HumanCatchCopy>
        <BusinessHumanExamples>
            {explanations.map((p,index) => (
                <VisionLists key={`VisionLists${index}`} list={p.list} sentence={p.sentence} />
            ))}
            {seminars.map((p,index) => (
                <AboutSeminars key={`AboutSeminars${index}`}>{p}</AboutSeminars>
            ))}
        </BusinessHumanExamples>
    </BusinessHumanOuter>
)

}
