import React from "react"
import styled from "styled-components"

const TopicBarOuter = styled.div`
    display: flex;
    flex-direction: column;
    height: 43px;
    width: 100%;
    background-color: #333333;
    justify-content: center;
    margin-bottom: 20px;
`

const TopicBarJapanese = styled.h3`
    display: inline-block;
    color: white;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.04em;
    margin-left: 10px;
`

const TopicBarEnglish = styled.h3`
    display: inline-block;
    color: white;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.04em;
    margin-left: 10px;
    margin-top: 3px;
`

const MobileVisionTopicBar = (props) => {

    return(
        <TopicBarOuter>
            <TopicBarJapanese>{props.japanese}</TopicBarJapanese>
            <TopicBarEnglish>{props.english}</TopicBarEnglish>
        </TopicBarOuter>
    )
}

export default MobileVisionTopicBar