import React from "react"
import styled from "styled-components"
import Spacer from "../../PC/atoms/Spacer"
import MobileVisionCatchcopy from "../molecules/MobileVisionCatchCopy"
import MobileVisionExecutives from "../molecules/MobileVisionExecutives"
import { MobileMemberButton } from "../atoms/MobileButton"

const VisionTopOuter = styled.div`
    display: inline-block;
    width: 100%;
    height: auto;
`
const MobileActionButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

`
const MobileVisionTop = () => {

    return (
        <VisionTopOuter>
            <MobileVisionCatchcopy />
            <MobileVisionExecutives />
            <Spacer height="100px" />
            <MobileActionButtonWrapper>
                <MobileMemberButton />
            </MobileActionButtonWrapper>
            <Spacer height="50px" />
        </VisionTopOuter>
    )
}

export default MobileVisionTop