import React from "react"
import styled from "styled-components"
import VisionExecutive from "../atoms/VisionExecutive"
import VisionImages from "../../Images/VisionImages"

const Executives = styled.div`
    display: inline-block;
    width: 100%;
    height: auto;
`
const ExecutivesWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 150px;
    background-color: white;
    justify-content: center;
`

const VisionExecutives = () => {

    const profiles = [
        {
            image: VisionImages("ceo"),
            position:"代表取締役最高経営責任者 / CEO",
            name:"下岡 純一郎"
        },
        {
            image: VisionImages("cto"),
            position:"代表取締役最高技術責任者 / CTO",
            name:"中野 雅俊"
        }
    ];


    return (
        <Executives>
            <ExecutivesWrapper>
                {profiles.map((p,index) => (
                    <VisionExecutive key={`VisionExecutive${index}`} image={p.image} position={p.position} name={p.name}  />
                ))}
            </ExecutivesWrapper>
        </Executives>
    )
}

export default VisionExecutives