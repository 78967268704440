import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Executive = styled.div`
    display: inline-block;
    margin: 40px 10px;
    text-align: center;
    width: 240px;
`
const ExecutivePosition = styled.div`
    display: inline-block;
    font-size: 11px;
    margin: 10px 0px;
    width: 100%;
    letter-spacing: 0.3px;
    line-height: 17px;
`
const ExecutiveName = styled.div`
    display: inline-block;
    font-size: 18px;
    letter-spacing: 3px;
`


const MobileVisionExecutive = (props) => {

    return (
        <Executive>
            <Img fixed={props.image} />
            <ExecutivePosition>{props.position}</ ExecutivePosition>
            <ExecutiveName>{props.name}</ ExecutiveName>
        </Executive>
    )
}

export default MobileVisionExecutive