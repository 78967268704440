import { useStaticQuery, graphql } from "gatsby"

const MobileVisionImages = (request) => {
    const data = useStaticQuery(graphql`
        query {
            ceo: file(relativePath: { eq: "PC/vision-picture-ceo.png" }) {
                childImageSharp {
                    fixed(width: 150, height: 192) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            cto: file(relativePath: { eq: "PC/vision-picture-cto2.png" }) {
                childImageSharp {
                    fixed(width: 150, height: 192) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
        }
    `)

    return data[request].childImageSharp.fixed
}

export default MobileVisionImages