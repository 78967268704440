import React from "react"
import styled from "styled-components"
import MobileVisionTopicBar from "../atoms/MobileVisionTopicBar"
import MobileVisionLists from "../atoms/MobileVisionLists"

const BusinessIndustryOuter = styled.div`
    display: inline-block;
    width: 100%;
`
const IndustryCatchCopy = styled.p`
    font-weight: 400;
    line-height: 17px;
`

const IndustryExampleLists = styled.ul`
    list-style: inside;
    padding-top: 15px;
`

const IndustryExampleList = styled.li`
    font-size: 12px;
    display: list-item;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 17px;
`

export const MobileVisionBusinessIndustry = () => {

    const IndustryExamples = [
        "釣り具のポイントのコミュニティ創造事業",
        "岡野バルブの次世代バルブメンテナンス事業",
        "福岡ひびき信用金庫のATM不正検知システム",
        "クラウン製パンの技術者育成システム",
        "受付AI窓口システム",
        "生体バイオセンサの鮮度トラッキングシステム",
        "次世代ECサイト",
        "AR/VRコミュニケーション"
    ]

    return (
        <BusinessIndustryOuter>
            <MobileVisionTopicBar japanese="未来産業創造事業"　english="UPDATE THE INDUSTRY" />
            <IndustryCatchCopy>地域の産業基盤・地域資源を活かした新しい事業を創造し、世界市場でマーケットリーダーとなるような事業体を生み出す。</IndustryCatchCopy>
            <IndustryExampleLists>
                {IndustryExamples.map((p,index) => (
                    <IndustryExampleList key={`IndExampleLists${index}`}>{p}</IndustryExampleList>
                ))}
            </IndustryExampleLists>
        </BusinessIndustryOuter>
    )

}

const BusinessHumanOuter = styled.div`
    display: inline-block;
    width: 100%;
`
const HumanCatchCopy = styled.p`
    font-weight: 400;
    line-height: 17px;
`

const AboutSeminars = styled.li`
    font-size: 12px;
    padding-top: 3px;
    list-style-type: none;
    line-height: 17px;
`

const BusinessHumanExamples = styled.div`
    font-size: 14px;
`

export const MobileVisionBusinessHuman = () => {

    const explanations = [
        {
            list: "出向/インターンシップ",
            sentence: "クアンドへ出向し、実践の中で経験を積むことで、総合力を鍛える。"
        },
        {
            list: "アドバイザリー/コンサルティング",
            sentence: "専門家派遣により、企業の持つ課題の抽出、戦略の策定、組織改革など幅広く支援。"
        },
        {
            list: "セミナー/ワークショップ",
            sentence: "これからの時代を生き抜くために必要な、基礎教養としての知識を提供。"
        }
    ];

    const seminars = [
        "- AI/IoT（基礎編、技術編、実践編）",
        "- マーケティング・ブランディング",
        "- リーダーシップ",
        "- デザインシンキング　　など"
    ];

return (
    <BusinessHumanOuter>
        <MobileVisionTopicBar japanese="未来人材育成事業" english="UNLEASH THE POWER OF YOU" />
        <HumanCatchCopy>テクノロジーとマーケットを深く理解し、他者を巻き込みながら、新しい事業が生み出せる次世代のリーダーやチームを創出。</HumanCatchCopy>
        <BusinessHumanExamples>
            {explanations.map((p,index) => (
                <MobileVisionLists key={`MobileVisionLists${index}`} list={p.list} sentence={p.sentence} />
            ))}
            {seminars.map((p,index) => (
                        <AboutSeminars key={`AboutSeminars${index}`}>{p}</AboutSeminars>
            ))}
        </BusinessHumanExamples>
    </BusinessHumanOuter>
)

}
