import React from "react"
import styled from "styled-components"

const BusinessHumanLists = styled.div`
    padding-top: 15px;
`

const BusinessHumanList = styled.li`
    font-size: 16px;
    display: list-item;
    margin: 15px 0px 15px 0px;
`

const HumanExplanation = styled.p`
    font-weight: 400;
`

const VisionLists = (props) => {

    return (
        <BusinessHumanLists>
            <BusinessHumanList>{props.list}</BusinessHumanList>
            <HumanExplanation>{props.sentence}</HumanExplanation>
        </BusinessHumanLists>
    )
}

export default VisionLists